<template>
  <div class="video-upload-container">
    <div v-if="!video" class="upload-area" @click="triggerFileInput" @dragover.prevent="dragover" @dragleave.prevent="dragleave" @drop.prevent="drop" :class="{ 'drag-over': isDragging }">
      <input type="file" ref="fileInput" @change="onFileSelected" accept="video/*" class="file-input" />
      <div class="upload-icon">
        <i class="fas fa-video"></i>
      </div>
      <p class="upload-text">点击或拖放视频到这里上传</p>
    </div>
    <div v-if="video" class="video-preview-container">
      <div class="video-preview">
        <video :src="video.url" :alt="video.name" class="preview-video" controls></video>
        <div class="video-overlay">
          <span class="video-name">{{ video.name }}</span>
        </div>
        <button @click="removeVideo" class="remove-button" aria-label="删除视频">
          <i class="fas fa-trash-alt"></i> 删除
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue';

const emit = defineEmits(['video-uploaded','video-uploaded-file']); // 定义事件
const fileInput = ref(null);
const isDragging = ref(false);
const video = ref(null); // 初始化视频对象

const triggerFileInput = () => {
  fileInput.value.click();
};

const dragover = (e) => {
  isDragging.value = true;
};

const dragleave = (e) => {
  isDragging.value = false;
};

const drop = (e) => {
  isDragging.value = false;
  const droppedFile = e.dataTransfer.files[0];
  handleFile(droppedFile);
};

// 处理文件选择
const onFileSelected = (event) => {
  const file = event.target.files[0];
  handleFile(file);
};

const handleFile = (file) => {
  if (file && file.type.startsWith('video/')) {
    // 创建 Blob URL
    const blobUrl = URL.createObjectURL(file);
    
    video.value = {
      name: file.name,
      url: blobUrl
    };
    emit('video-uploaded', blobUrl); // 触发自定义事件并传递视频 URL
    emit('video-uploaded-file', file); // 触发自定义事件并传递视频 URL
    console.log('视频上传:',blobUrl);
  } else {
    alert(`文件 ${file ? file.name : ''} 不是视频文件。请只上传视频文件。`);
  }
};

const removeVideo = () => {
  video.value = null;
  if (fileInput.value) {
    fileInput.value.value = '';
  }
  cleanupBlobUrlOnRemove();
};

const cleanupBlobUrlOnRemove = () => {
  if (video.value && video.value.url) {
    URL.revokeObjectURL(video.value.url);
  }
};
</script>

<style scoped>
.video-upload-container {
  max-width: 300px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.upload-area {
  width: 200px;
  height: 150px;
  line-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #3498db;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.upload-area:hover, .drag-over {
  background-color: #ecf0f1;
}

.file-input {
  display: none;
}

.upload-icon {
  font-size: 48px;
  color: #3498db;
  margin-bottom: 10px;
}

.upload-text {
  font-size: 16px;
  color: #aad820;
}

.video-preview-container {
  /* margin-top: 20px; */
}

.video-preview {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.preview-video {
  width: 100%;
  height: 160px;
  object-fit: cover;
}

.video-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px;
  font-size: 12px;
}

.video-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.remove-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 5px;
}

.remove-button:hover {
  background-color: #c0392b;
}

.remove-button i {
  font-size: 16px;
}

@media (max-width: 400px) {
  .video-upload-container {
    max-width: 100%;
  }
}
</style>
// store/user.js
import { defineStore } from 'pinia';

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    isLoggedIn: false,
    username: '',
    password: '',
    openid: '',
    drawNum: 0,
    showLoginPopup: false
  }),
  actions: {
    login(username, password) {
      this.username = username;
      this.password = password;
      this.isLoggedIn = true;
      this.showLoginPopup = false;
    },
    logout() {
      this.isLoggedIn = false;
      this.username = '';
      this.password = '';
      this.openid = '';
      this.drawNum = 0;
      this.showLoginPopup = false;
      localStorage.removeItem("user");
    },
    setShowLoginPopup(value) {
      this.showLoginPopup = value;
    }
  }
});
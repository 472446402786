// 路由配置
import RunwayGen3 from '@/views/work/Runway Gen3.vue';
import runway from '@/views/work/runway.vue';
import Wenshengvideo from '@/views/work/Wensheng video.vue';
import oldphotos from '@/views/work/Old photos.vue';
import funnyvideos from '@/views/work/Funny videos.vue';
import luma from '@/views/work/luma.vue';
import kling from '@/views/work/kling.vue';
import Actionvideos from '@/views/work/Action videos.vue';
import Videogenerates from '@/views/work/Video generates.vue';
import Myvideos from '@/views/Myvideos.vue';
import Square from '@/views/Video Square.vue';
import cesi from '../views/cesi.vue';
import Home from '@/views/Home.vue';
import syhbs from '@/views/syhbs.vue';
import { createRouter, createWebHistory } from 'vue-router';
import Pay from '@/views/pay.vue';
import { useUserStore } from '@/views/js/user';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/syhbs',
        name: 'syhbs',
        component: syhbs
    },
    {
        path: '/RunwayGen3',
        name: 'RunwayGen3',
        component: RunwayGen3
    },
    {
        path: '/Videogenerates',
        name: 'Videogenerates',
        component: Videogenerates
    },
    {
        path: '/runway',
        name: 'runway',
        component: runway,
    },
    {
        path: '/cesi',
        name: 'cesi',
        component: cesi,
    },
    {
        path: '/Wenshengvideo',
        name: 'Wenshengvideo',
        component: Wenshengvideo
    },
    {
        path: '/oldphotos',
        name: 'oldphotos',
        component: oldphotos
    },
    {
        path: '/funnyvideos',
        name: 'funnyvideos',
        component: funnyvideos
    },
    {
        path: '/luma',
        name: 'luma',
        component: luma
    },
    {
        path: '/kling',
        name: 'kling',
        component: kling
    },
    {
        path: '/Actionvideos',
        name: 'Actionvideos',
        component: Actionvideos
    },
    {
        path: '/Myvideos',
        name: 'Myvideos',
        component: Myvideos
    },
    {
        path: '/Square',
        name: 'Square',
        component: Square
    },
    {
        path: '/pay',
        name: 'pay',
        component: Pay
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    const userStore = useUserStore();
    if(to.meta.requiresAuth &&!userStore.isLoggedIn){
        userStore.showLoginPouup();
        next('/');
    }else{
        next();
    }
    });

export default router;

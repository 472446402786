<template>
  <div class="image-upload-container">
    <div v-if="!image" class="upload-area" @click="triggerFileInput" @dragover.prevent="dragover" @dragleave.prevent="dragleave" @drop.prevent="drop" :class="{ 'drag-over': isDragging }">
      <input type="file" ref="fileInput" @change="onFileSelected" accept="image/*" class="file-input" />
      <div class="upload-icon">
        <i class="fas fa-cloud-upload-alt"></i>
      </div>
      <p class="upload-text">点击或拖放图片到这里上传</p>
    </div>
    <div v-if="image" class="image-preview-container">
      <div class="image-preview">
        <img :src="image.url" :alt="image.name" class="preview-img" />
        <div class="image-overlay">
          <span class="image-name">{{ image.name }}</span>
        </div>
        <button @click="removeImage" class="remove-button" aria-label="删除图片">
          <i class="fas fa-trash-alt"></i> 删除
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue';


const emit = defineEmits(['image-uploaded']); // 定义事件
const fileInput = ref(null);
const isDragging = ref(false);
const image = ref(null);

const triggerFileInput = () => {
  fileInput.value.click();
};

const dragover = (e) => {
  isDragging.value = true;
};

const dragleave = (e) => {
  isDragging.value = false;
};

const drop = (e) => {
  isDragging.value = false;
  const droppedFile = e.dataTransfer.files[0];
  handleFile(droppedFile);
};

const onFileSelected = (e) => {
  const selectedFile = e.target.files[0];
  handleFile(selectedFile);
};

const handleFile = (file) => {
  if (file && file.type.startsWith('image/')) {
    const reader = new FileReader();
    reader.onload = (e) => {
      image.value = {
        name: file.name,
        url: e.target.result
      };
      emit('image-uploaded', e.target.result); // 触发自定义事件并传递图片 URL
    };
    reader.readAsDataURL(file);
  } else {
    alert(`文件 ${file ? file.name : ''} 不是图片文件。请只上传图片文件。`);
  };
};

const removeImage = () => {
  image.value = null;
  if (fileInput.value) {
    fileInput.value.value = '';
  }
};
</script>

<style scoped>
.image-upload-container {
  max-width: 300px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.upload-area {
  width: 200px;
  height: 150px;
  line-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #3498db;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.upload-area:hover, .drag-over {
  background-color: #ecf0f1;
}

.file-input {
  display: none;
}

.upload-icon {
  font-size: 48px;
  color: #3498db;
  margin-bottom: 10px;
}

.upload-text {
  font-size: 16px;
  color: #aad820;
}

.image-preview-container {
  /* margin-top: 20px; */
}

.image-preview {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.preview-img {
  width: 100%;
  height: 160px;
  object-fit: cover;
}

.image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px;
  font-size: 12px;
}

.image-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.remove-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 5px;
}

.remove-button:hover {
  background-color: #c0392b;
}

.remove-button i {
  font-size: 16px;
}

@media (max-width: 400px) {
  .image-upload-container {
    max-width: 100%;
  }
}
</style>
<script setup>
import { ref, defineEmits } from 'vue';

const emit = defineEmits();

const video = ref({ name: '', url: '' });
const blobUrl = ref('');

// 处理文件上传
const handleFile = (file) => {
  if (file && file.type.startsWith('video/')) {
    // 创建 Blob URL
    blobUrl.value = URL.createObjectURL(file);
    
    video.value = {
      name: file.name,
      url: blobUrl.value
    };
    
    emit('video-uploaded', blobUrl.value);
    emit('video-uploaded-file', file);
    
    // 清理 Blob URL
    cleanupBlobUrlOnRemove();
  } else {
    alert(`文件 ${file ? file.name : ''} 不是视频文件。请只上传视频文件。`);
  }
};

// 删除视频的逻辑
const removeVideo = () => {
  // 清理 Blob URL 等逻辑
  if (blobUrl.value) {
    URL.revokeObjectURL(blobUrl.value); // 释放 Blob URL
    blobUrl.value = ''; // 清空
  }
};

// 其他逻辑可以继续...
</script>

<template>
  <div>
    <!-- 这里可以根据需求使用 blobUrl -->
    <video v-if="blobUrl" :src="blobUrl" controls></video>
    <!-- 其他模板内容 -->
  </div>
</template>

<template>
  <div>
    <div v-html="htmlContent"></div> <!-- 渲染 HTML 内容 -->
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const htmlContent = ref('')

onMounted(() => {
  // 从路由中获取参数
  htmlContent.value = route.query.htmlContent || ''
})
</script>